import React from "react";
import { Link } from 'react-scroll';


import { HiArrowNarrowRight } from "react-icons/hi";

const Home = () => {
  return (
    <div name="home" className=" w-full h-screen bg-[#0a192f]">
      {/* Container */}

      <div className="max-w-[1000px] mx-auto px-5 flex flex-col justify-center h-full">
        <p className="text-pink-600">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Mohamed Tarek
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          {/* I'm FrontEnd React Developer */}
          I'm  Full Stack Developer
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          {/* I'm a civil engineer and front end react developer specializing in
          building (and occasionally designing) exceptional digital experiences.
          Currently, I’m focused on building responsive front-end web
          applications. */}


  with a background in civil engineering and expertise in FrontEnd React development.
  I specialize in crafting outstanding digital experiences through a combination of robust back-end solutions and intuitive front-end interfaces.
  My journey began as a civil engineer, and I've seamlessly transitioned into the world of technology,
  where I now thrive in building both responsive front-end interfaces and powerful back-end functionalities.
  My focus extends beyond the user interface, encompassing the entire web development process.
  Let's connect and discuss how I can bring a holistic approach to your next project!

        </p>
        <div >

          <Link to='work' smooth={true} duration={500} >
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
              View Work
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>

          </Link>

        </div>
      </div>

    </div>
  );
};

export default Home;
