import {
  HTML,
  Git,
  Figma,
  Solidity,
  Redux,
  tailwindCss,
  typeScript,
  next,
  ReactImg,
  JavaScript,
  CSS,
  Node
} from "../assets/index";

const skills = [
  { name: "HTML", image: HTML },
  { name: "CSS", image: CSS },
  { name: "JAVASCRIPT", image: JavaScript },
  { name: "REACT JS", image: ReactImg },
  { name: "NEXT JS", image: next },
  { name: "Git", image: Git },
  { name: "NODE JS", image: Node },
  { name: "TypeScript", image: typeScript },
  { name: "Tailwind CSS", image: tailwindCss },
  { name: "Redux", image: Redux },
  { name: "Solidity", image: Solidity },
  { name: "Figma", image: Figma },
];

export default skills;