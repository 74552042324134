import About from "./comp/About";
import Contact from "./comp/Contact";
import Home from "./comp/Home";
import Navbar from "./comp/Navbar";
import Skills from "./comp/Skills";
import Work from "./comp/Work";

function App() {
  return (
    <div >
       <Navbar/>
       <Home/>
       <About/>
       <Skills/>
       <Work/>
       <Contact/>
    </div>
  );
}

export default App;
