import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className=' w-full h-[800px]  bg-[#0a192f] flex justify-center items-center py-20  ' >
      
      <form method='POST' action='https://getform.io/f/a26e693c-0ef3-4b77-920e-4070c87612b1' className='flex flex-col max-w-[600px] w-full'>
          <div className='pb-8'>
              <p className='text-4xl font-bold inline border-b-4 border-purple-600 text-gray-300'>Contact</p>
              <p className='text-gray-300 py-4'>
             {/* // GET IN TOUCH  */}

            {/* // Get in touch */}
             
           // Get in touch and Submit the form below
           {/* // Get in touch Submit the form below or shoot me an email - myemail@gmail.com */}

              </p>
          </div>
          <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name='name' />
          <input className='my-4 p-2 bg-[#ccd6f6] ' type="email" placeholder='Email' name='email' />
          <textarea className='bg-[#ccd6f6] p-2' name='message' rows='10' placeholder='Message'></textarea>
          <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>


      </form>
    
    </div>
  )
}

export default Contact