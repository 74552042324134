import {
  Ecommerce,
  gym,
  gym1,
  AstMedia,
  warehouse,
  filmpire,
  cryptoket,
  metaversus,
  CryptoRedux,
  memories,
  Keeper,
  Defi,
  crowdfunding,
  nft,
  hoobank,
  travel,
  tasksmanger,
  Auth,
  AlTarek,
  AlTarekAdmin,
} from "../photos/index";
const projects = [
  {
    name: "Filmpire AI Powered Movie App",

    image: filmpire,
    link: "https://filmpire.mtarex.com/",
  },
  {
    // name: "CryptoKet full stack- Web3 NFT marketplace ",
    name: "CryptoKet Full Stack Web3  NFT marketplace ",

    image: cryptoket,
    link: "https://cryptoket3.vercel.app/",
  },
  {
    name: " Golds Gym AI Powered Gym App",

    image: gym1,
    link: "https://goldsgym.mtarex.com/",
  },

  {
    name: "Full-Stack Warehouse",

    image: warehouse,
    link: "https://al-tarek-warehouse.vercel.app/",
  },
  {
    name: "Full Stack E-Commerce",

    image: Ecommerce,
    link: "https://altarek-ecommerce.vercel.app/",
  },

  ,
  {
    name: "Memories MERN Full Stack APP",

    image: memories,
    link: "https://memeries.vercel.app/",
  },

  {
    name: "Ast Media",

    image: AstMedia,
    link: "https://ast-media1101.vercel.app/",
  },

  {
    name: "Al tarek Real Estates ",

    image: AlTarek,
    link: "https://al-tarek-web-client.vercel.app/",
  },
  {
    name: "Al tarek Real Estates - Admin",

    image: AlTarekAdmin,
    link: "https://al-tarek-web-admin.vercel.app/",
  },

  {
    name: "Metaverse",

    image: metaversus,
    link: "https://metaversus.mtarex.com/",
  },

  {
    name: "Full-Stack Tasks Manger",

    image: tasksmanger,
    link: "https://tasks-manager-tarek.netlify.app/",
  },

  {
    name: "Crypto App with redux toolKit and Ant Design",

    image: CryptoRedux,
    link: "http://crypto.mtarex.com",
  },

  ,
  // {
  //   name: "Full-Stack Authentication",

  //   image: Auth,
  //   link: "https://next-auth-tarek.vercel.app/",
  // },

  // {
  //   name: "Memories MERN Full Stack APP",

  //   image: memories,
  //   link: "https://memories.mtarex.com",
  // }

  // {
  //   name: "Memories MERN Full Stack APP",

  //   image: memories,
  //   link: "https://memories.mtarex.com",
  // }

  // , {
  //   name: "Keeper App",

  //   image: Keeper,
  //   link: "http://keeper.mtarex.com",
  // }
  // ,

  //  ,
  //    {
  //     name: "Defi",

  //     image: Defi,
  //     link: "http://defi.mtarex.com",
  //   }

  {
    name: "crowdfunding Web3",

    image: crowdfunding,
    link: "https://crowdfunding.mtarex.com/",
  },

  {
    name: "NFT MARKETPLACE",

    image: nft,
    link: "http://nft.mtarex.com",
  },

  // {
  //   name: "HooBank",

  //   image: hoobank,
  //   link: "https://hoobank.mtarex.com/#home",
  // },

  {
    name: "Travel App",

    image: travel,
    link: "http://travel.mtarex.com",
  },
];

export default projects;
