import React, { useEffect } from "react";
import projects from "../utlis/projects";

const Work = () => {
  useEffect(() => {
    console.log(projects);
  }, []);
  return (
    <div
      name="work"
      className="w-full md:h-[1800px] py-20 text-gray-300 bg-[#0a192f]"
    >
      <div className="max-w-[1000px] mx-auto p-4  flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600">
            Work
          </p>
          <p className="py-6 w-[80%] font-medium">
            {/* // Check out some of my recent work */}
            Following projects showcases my skills and experience through
            examples of my work. Each project is It reflects my ability to solve
            complex problems, work with different technologies, and manage
            projects effectively.
          </p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Grid Item */}

          {projects.map((project) => (
            <div
              key={project.name}
              style={{ backgroundImage: `url(${project.image})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100 text-center">
                <span className="text-2xl font-bold text-white tracking-wider">
                  {/* Filmpire AI Powered Movie App */}
                  {project.name}
                </span>
                <div className="pt-8 text-center">
                  <a href={project.link}>
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  {/* <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
